// axiosConfig.ts
import axios from 'axios'
import { tokenRefreshLink } from '../utils/Constants'

const SERVER_LOCATION = process.env.REACT_APP_SERVER_LOCATION || 'http://localhost:8000'

const instance = axios.create({
    baseURL: `${SERVER_LOCATION}`, // Set your base URL here
    timeout: 5000 // Set a timeout for requests (optional)
})

instance.defaults.withCredentials = true

instance.interceptors.request.use(
    (config) => {
        // Get the accessToken from your authentication state
        const accessToken: string | null = sessionStorage.getItem('accessToken')
        // Set the accessToken in the request header
        if (accessToken && accessToken.length > 0) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
    },
    (error) => {
        console.error('Error in request', error)
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        const { accessToken, expireDate } = response.data

        if (expireDate) {
            sessionStorage.setItem('expireDate', expireDate)
        }

        if (accessToken) {
            sessionStorage.setItem('accessToken', accessToken)
        }

        return response
    },
    async (error) => {
        const originalRequest = error.config

        // Check if the error is due to an unauthorized request
        if (error.response?.status === 400 && !originalRequest._retry) {
            originalRequest._retry = true // Prevent infinite loop
            try {
                // Attempt to refresh the access token
                const response = await instance.post(tokenRefreshLink)
                const newAccessToken = response.data.accessToken
                sessionStorage.setItem('accessToken', newAccessToken) // Update your state with the new access token
                sessionStorage.setItem('expireDate', response.data.expireDate)

                // Update the original request's Authorization header
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

                // Retry the original request with the new token
                return instance(originalRequest)
            } catch (tokenRefreshError) {
                // Handle token refresh failure (e.g., logout the user)
                console.error('Token refresh failed', tokenRefreshError)
                return Promise.reject(tokenRefreshError)
            }
        }

        // Handle other errors
        return Promise.reject(error)
    }
)

export default instance
