import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material'
import React from 'react'
import { ButtonTypes, defaultCard } from 'utils/Util'
import { getLabel } from '../labels/labelUtils'

const buttonStyle = {
    Add: { backgroundColor: 'aqua', color: 'white', height: '24px', verticalAlign: 'middle' },
    Remove: { backgroundColor: 'transparent', color: 'black', height: '24px', verticalAlign: 'middle' }
}

interface Props {
    card: defaultCard
    type: ButtonTypes
    buttonClicked: (card: defaultCard) => void
}

export const OrderResumeComponent = ({ card, type, buttonClicked }: Props): JSX.Element => {
    return (
        <Stack
            direction='row'
            sx={{ flexGrow: 1, border: 1 }}>
            <Stack direction='column'>
                <Stack
                    direction='row'
                    spacing={1}
                    ml={1}
                    display='flex'>
                    <Box sx={{ display: 'flex', mt: 1.75, ml: 1 }}>
                        <SvgIcon component={card.icon} />
                    </Box>
                    <Typography variant='h5'>{getLabel(card.title)}</Typography>
                </Stack>
                <Typography
                    ml={1}
                    variant='body2'>
                    {getLabel(card.description)}
                </Typography>
            </Stack>
            <Box sx={{ width: '100%', alignItems: 'end', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant='contained'
                    onClick={() => buttonClicked(card)}
                    sx={{ ...buttonStyle[type] }}>
                    {type}
                </Button>
            </Box>
        </Stack>
    )
}
