import { isAxiosError } from 'axios'
// eslint-disable-next-line import/named
import { JwtPayload } from 'jwt-decode'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { checkIfPublicLink, importResumeLink, registerGoogleLink, registerLink, updatePictureLink, viewResumeLink } from './Constants'

export interface registerReturnInterface {
    user: IUser | undefined
    resume: IResume | undefined
    token: string | undefined
    error?: string
}

export const register = async (emailValue: string, passwordValue: string, firstNameValue: string, lastNameValue: string): Promise<registerReturnInterface> => {
    try {
        const response = await axios.post(registerLink, {
            email: emailValue,
            password: passwordValue,
            firstName: firstNameValue,
            lastName: lastNameValue
        })

        const { user, resume, token } = response.data
        return { user, resume, token }
    } catch (err) {
        if (isAxiosError(err) && err.response) {
            return { user: undefined, resume: undefined, token: undefined, error: err.response.data.message }
        } else {
            return { user: undefined, resume: undefined, token: undefined, error: 'An unknown error occurred' }
        }
    }
}

export const registerGoogle = async (googleUser: JwtPayload, subdomain: string): Promise<registerReturnInterface> => {
    try {
        const response = await axios.post(registerGoogleLink, {
            googleUser,
            subdomain
        })

        const { user, resume, token } = response.data
        return { user, resume, token, error: undefined }
    } catch (err) {
        if (isAxiosError(err) && err.response) {
            return { user: undefined, resume: undefined, token: undefined, error: err.response.data.message }
        } else {
            return { user: undefined, resume: undefined, token: undefined, error: 'An unknown error occurred' }
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePictureResume = async (id: string, value: any) => {
    await axios
        .post(updatePictureLink(id), {
            fieldValue: value
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })
}

export const checkIfResumePublic = async (resumeId: string): Promise<boolean> => {
    let isPublic = false
    await axios
        .get(checkIfPublicLink(resumeId))
        .then((val) => {
            isPublic = val.data.isPublic
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })

    return isPublic
}

export const viewResume = async (domain: string, firstName: string, lastName: string, resumeName: string, location: string, state: string): Promise<IResume | undefined> => {
    let resume: IResume | undefined = undefined

    await axios
        .get(viewResumeLink(domain, firstName, lastName, resumeName, location, state))
        .then((val) => {
            resume = val.data.resume
        })
        .catch((err) => {
            console.error(JSON.stringify(err))
        })

    return resume
}

export const importResume = async (user: IUser, ImportFile: File, RevisionDate: string) => {
    const formData = new FormData()
    formData.append('userEmail', user.email)
    // formData.append('processTK', 'true')
    formData.append('ImportFile', ImportFile)
    formData.append('RevisionDate', RevisionDate)

    let resume = undefined
    await axios
        .post(importResumeLink, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            resume = response.data.resume
        })
        .catch((error) => {
            console.error(error)
            throw error
        })

    return resume
}
