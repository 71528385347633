import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, CircularProgress, Divider, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { getLabel as getLabelDefault } from '../../labels/labelUtils'
import { IEducation } from '../../models/Education'
import { IEmployment } from '../../models/Employment'
import { IProject } from '../../models/Project'
import { IPublication } from '../../models/Publication'
import { IReference } from '../../models/Reference'
import { ISimpleCategory } from '../../models/SimpleList'
import { ITalent } from '../../models/Talent'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'
import { capitalizeFirstLetter, mapCategories, setNestedProperty, toReadableString } from '../../utils/Util'
import QuillEditor from '../forms/QuillEditor'
import EditModalTitle from './EditModalTitle'
import EducationEditModalComponent from './EducationEditModalComponent'
import EmploymentEditModalComponent from './EmploymentEditModalComponent'
import PersonalInfoEditModalComponent from './PersonalInfoEditModalComponent'
import ProjectEditModalComponent from './ProjectEditModalComponent'
import PublicationEditModalComponent from './PublicationEditModalComponent'
import ReferencesEditModalComponent from './ReferencesEditModalComponent'
import SimpleListEditModalComponent from './SimpleListEditModalComponent'
import TalentEditModalComponent from './TalentEditModalComponent'

interface EditModalComponentProps {
    open: boolean
    onClose: () => void
    title?: string
    names?: string[]
    width?: number
    loading?: boolean
    isSkills?: boolean
    isEmployment?: boolean
    isEducation?: boolean
    isAddress?: boolean
    isAccomplishments?: boolean
    isCapabilities?: boolean
    isAffiliations?: boolean
    isHonors?: boolean
    isLicenses?: boolean
    isTrainings?: boolean
    isVolunteers?: boolean
    isPublications?: boolean
    isProjects?: boolean
    isTalents?: boolean
    isReferences?: boolean
    hideTitle?: boolean
}

type FieldMap = {
    'accomplishments.category': ISimpleCategory[]
    'capabilities.category': ISimpleCategory[]
    'skills.category': ISimpleCategory[]
    'personalInfo.address': string
    'employments.employment': IEmployment[]
    'educations.education': IEducation[]
    'affiliations.affiliation': IEmployment[]
    'honors.honor': IEmployment[]
    'licenses.license': IEmployment[]
    'trainings.training': IEmployment[]
    'volunteers.volunteer': IEmployment[]
    'publications.publication': IPublication[]
    'projects.topics': IProject[]
    'talents.talent': ITalent[]
    'references.reference': IReference[]
    [key: string]: unknown
}

const EditModal: React.FC<EditModalComponentProps> = ({
    open,
    onClose,
    title,
    names,
    width = 750,
    loading,
    isSkills = false,
    isEmployment = false,
    isEducation = false,
    isAddress = false,
    isAccomplishments = false,
    isCapabilities = false,
    isAffiliations = false,
    isHonors = false,
    isLicenses = false,
    isTrainings = false,
    isVolunteers = false,
    isPublications = false,
    isProjects = false,
    isTalents = false,
    isReferences = false,
    hideTitle = false
}) => {
    const { mutate: updateResumeField } = useUpdateResumeField()
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { getValues, reset, setValue } = useFormContext()

    const initialSkills: ISimpleCategory[] = euContext.resume.skills.category ?? []
    const initialEmploymentList: IEmployment[] = euContext.resume.employments.employment ?? []
    const initialEducationList: IEducation[] = euContext.resume.educations.education ?? []
    const initialAccomplishments: ISimpleCategory[] = euContext.resume.accomplishments.category ?? []
    const initialCapabilities: ISimpleCategory[] = euContext.resume.capabilities.category ?? []
    const initialAffiliations: IEmployment[] = euContext.resume.affiliations.affiliation ?? []
    const initialHonors: IEmployment[] = euContext.resume.honors.honor ?? []
    const initialLicenses: IEmployment[] = euContext.resume.licenses.license ?? []
    const initialTrainings: IEmployment[] = euContext.resume.trainings.training ?? []
    const initialVolunteers: IEmployment[] = euContext.resume.volunteers.volunteer ?? []
    const initialPublications: IPublication[] = euContext.resume.publications.publication ?? []
    const initialProjects: IProject[] = euContext.resume.projects.topics ?? []
    const initialTalents: ITalent[] = euContext.resume.talents.talent ?? []
    const initialReferences: IReference[] = euContext.resume.references.reference ?? []

    const [accomplishments, setLocalAccomplishments] = useState<ISimpleCategory[]>(initialAccomplishments)
    const [volunteers, setVolunteers] = useState<IEmployment[]>(initialVolunteers)
    const [projects, setProjects] = useState<IProject[]>(initialProjects)
    const [publications, setPublications] = useState<IPublication[]>(initialPublications)
    const [talents, setTalents] = useState<ITalent[]>(initialTalents)
    const [references, setReferences] = useState<IReference[]>(initialReferences)
    const [trainings, setTrainings] = useState<IEmployment[]>(initialTrainings)
    const [honors, setHonors] = useState<IEmployment[]>(initialHonors)
    const [affiliations, setAffiliations] = useState<IEmployment[]>(initialAffiliations)
    const [licenses, setLicenses] = useState<IEmployment[]>(initialLicenses)
    const [capabilities, setLocalCapabilities] = useState<ISimpleCategory[]>(initialCapabilities)
    const [skills, setSkills] = useState<ISimpleCategory[]>(initialSkills || [{ name: 'Default Category', list: [], seqNum: 0 }])
    const [employmentList, setEmploymentList] = useState(initialEmploymentList)
    const [educationList, setEducationList] = useState<IEducation[]>(initialEducationList)
    const [selectedTab, setSelectedTab] = useState(0)

    const getLabel = () => {
        if (isAccomplishments) return euContext.resume.accomplishments.label
        if (isSkills) return euContext.resume.skills.label
        if (isEmployment) return euContext.resume.employments.label
        if (isAffiliations) return euContext.resume.affiliations.label
        if (isEducation) return euContext.resume.educations.label
        if (isHonors) return euContext.resume.honors.label
        if (isLicenses) return euContext.resume.licenses.label
        if (isTrainings) return euContext.resume.trainings.label
        if (isVolunteers) return euContext.resume.volunteers.label
        if (isCapabilities) return euContext.resume.capabilities.label
        if (isPublications) return euContext.resume.publications.label
        if (isProjects) return euContext.resume.projects.label
        if (isTalents) return euContext.resume.talents.label
        if (isReferences) return euContext.resume.references.label
        else {
            const name = names?.find((name) => name.includes('.label'))
            return name
                ? name.split('.').reduce((acc, part) => {
                      if (acc && typeof acc === 'object' && part in acc) {
                          return (acc as Record<string, unknown>)[part]
                      }
                      return undefined
                  }, euContext.resume as unknown)
                : title
        }
    }

    const [newLabel, setNewLabel] = useState(getLabel())

    const updateField = <T extends keyof FieldMap>(fieldName: T, fieldValue: FieldMap[T]) => {
        updateResumeField(
            {
                id: euContext.resume._id,
                fieldName: fieldName as string,
                fieldValue
            },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    reset(data.resume)
                    onClose()
                }
            }
        )
    }

    const handleSaveClick = () => {
        if (!euContext.resume._id) return

        if (newLabel !== getLabel()) {
            handleLabelSave(newLabel as string)
        }
        if (isProjects) {
            updateField('projects.topics', projects)
        }
        if (isTalents) {
            updateField('talents.talent', talents)
        }
        if (isReferences) {
            updateField('references.reference', references)
        }
        if (isPublications) {
            const sortedPublicationsList = [...publications].sort((a, b) => {
                return new Date(b.pubYear, b.pubMonth - 1).getTime() - new Date(a.pubYear, a.pubMonth - 1).getTime()
            })
            updateField('publications.publication', sortedPublicationsList)
        }
        if (isAccomplishments) {
            updateField('accomplishments.category', mapCategories(accomplishments))
        } else if (isVolunteers) {
            const sortedVolunteersList = [...volunteers].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('volunteers.volunteer', sortedVolunteersList)
        } else if (isTrainings) {
            const sortedTrainingsList = [...trainings].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('trainings.training', sortedTrainingsList)
        } else if (isLicenses) {
            const sortedLicensesList = [...licenses].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('licenses.license', sortedLicensesList)
        } else if (isHonors) {
            const sortedHonorsList = [...honors].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('honors.honor', sortedHonorsList)
        } else if (isAffiliations) {
            const sortedAffiliationsList = [...affiliations].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('affiliations.affiliation', sortedAffiliationsList)
        } else if (isCapabilities) {
            updateField('capabilities.category', mapCategories(capabilities))
        } else if (isSkills) {
            updateField('skills.category', mapCategories(skills))
        } else if (isAddress) {
            const address = getValues('personalInfo.address')
            updateField('personalInfo.address', address)
        } else if (isEmployment) {
            const sortedEmploymentList = [...employmentList].sort((a, b) => {
                if (a.isPresent && !b.isPresent) return -1
                if (!a.isPresent && b.isPresent) return 1
                return new Date(b.endYear, b.endMonth - 1).getTime() - new Date(a.endYear, a.endMonth - 1).getTime()
            })
            updateField('employments.employment', sortedEmploymentList)
        } else if (isEducation) {
            const sortedEducationList = [...educationList].sort((a, b) => {
                return new Date(b.graduationYear, b.graduationMonth - 1).getTime() - new Date(a.graduationYear, a.graduationMonth - 1).getTime()
            })
            updateField('educations.education', sortedEducationList)
        } else {
            names?.forEach((name) => {
                if (!name.includes('.label')) {
                    const fieldValue = getValues(name)
                    updateField(name, fieldValue)
                }
            })
        }
    }

    const handleCancel = () => {
        setSkills(initialSkills)
        setEmploymentList(initialEmploymentList)
        setEducationList(initialEducationList)
        setLocalAccomplishments(initialAccomplishments)
        setLocalCapabilities(initialCapabilities)
        setProjects(initialProjects)
        setTalents(initialTalents)
        setReferences(initialReferences)
        onClose()
    }

    const handleLabelSave = (newLabel: string) => {
        if (isAccomplishments) {
            euContext.resume.accomplishments.label = newLabel
            updateField('accomplishments.label', newLabel)
        } else if (isSkills) {
            euContext.resume.skills.label = newLabel
            updateField('skills.label', newLabel)
        } else if (isEmployment) {
            euContext.resume.employments.label = newLabel
            updateField('employments.label', newLabel)
        } else if (isEducation) {
            euContext.resume.educations.label = newLabel
            updateField('educations.label', newLabel)
        } else if (isAffiliations) {
            euContext.resume.affiliations.label = newLabel
            updateField('affiliations.label', newLabel)
        } else if (isHonors) {
            euContext.resume.honors.label = newLabel
            updateField('honors.label', newLabel)
        } else if (isLicenses) {
            euContext.resume.licenses.label = newLabel
            updateField('licenses.label', newLabel)
        } else if (isTrainings) {
            euContext.resume.trainings.label = newLabel
            updateField('trainings.label', newLabel)
        } else if (isVolunteers) {
            euContext.resume.volunteers.label = newLabel
            updateField('volunteers.label', newLabel)
        } else if (isCapabilities) {
            euContext.resume.capabilities.label = newLabel
            updateField('capabilities.label', newLabel)
        } else if (isPublications) {
            euContext.resume.publications.label = newLabel
            updateField('publications.label', newLabel)
        } else if (isProjects) {
            euContext.resume.projects.label = newLabel
            updateField('projects.label', newLabel)
        } else if (isTalents) {
            euContext.resume.talents.label = newLabel
            updateField('talents.label', newLabel)
        } else if (isReferences) {
            euContext.resume.references.label = newLabel
            updateField('references.label', newLabel)
        } else {
            const name = names?.find((name) => name.includes('.label'))
            if (name) {
                const parts = name.split('.')

                setNestedProperty(euContext.resume, parts, newLabel)
                updateField(name, newLabel)
                euContext.setResume({ ...euContext.resume })
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCancel}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'>
            <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                        maxHeight: 700,
                        overflowY: 'auto'
                    }}>
                    <IconButton
                        onClick={handleCancel}
                        sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CloseIcon />
                    </IconButton>
                    {!hideTitle && (
                        <EditModalTitle
                            label={newLabel as string}
                            variant='h4'
                            setNewLabel={setNewLabel}
                        />
                    )}
                    {!loading && (isEmployment || isAffiliations || isHonors || isLicenses || isTrainings || isVolunteers) && (
                        <EmploymentEditModalComponent
                            employmentList={isEmployment ? employmentList : isAffiliations ? affiliations : isHonors ? honors : isLicenses ? licenses : isTrainings ? trainings : volunteers}
                            setEmploymentList={
                                isEmployment ? setEmploymentList : isAffiliations ? setAffiliations : isHonors ? setHonors : isLicenses ? setLicenses : isTrainings ? setTrainings : setVolunteers
                            }
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            listType={isEmployment ? 'employment' : isAffiliations ? 'affiliations' : isHonors ? 'honors' : isLicenses ? 'licenses' : isTrainings ? 'trainings' : 'volunteers'}
                        />
                    )}

                    {!loading && isEducation && (
                        <EducationEditModalComponent
                            educationList={educationList}
                            setEducationList={setEducationList}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isProjects && (
                        <ProjectEditModalComponent
                            projectList={projects}
                            setProjects={setProjects}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isPublications && (
                        <PublicationEditModalComponent
                            publicationList={publications}
                            setPublicationList={setPublications}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isTalents && (
                        <TalentEditModalComponent
                            talentList={talents}
                            setTalentList={setTalents}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isReferences && (
                        <ReferencesEditModalComponent
                            referenceList={references}
                            setReferenceList={setReferences}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isAccomplishments && (
                        <SimpleListEditModalComponent
                            itemsList={accomplishments}
                            setItemsList={setLocalAccomplishments}
                            itemName={euContext.resume.accomplishments.label}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isSkills && (
                        <SimpleListEditModalComponent
                            itemsList={skills}
                            setItemsList={setSkills}
                            itemName={euContext.resume.skills.label}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading && isCapabilities && (
                        <SimpleListEditModalComponent
                            itemsList={capabilities}
                            setItemsList={setLocalCapabilities}
                            itemName={euContext.resume.capabilities.label}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    )}

                    {!loading &&
                        !isSkills &&
                        !isEmployment &&
                        !isEducation &&
                        !isCapabilities &&
                        !isAffiliations &&
                        !isHonors &&
                        !isLicenses &&
                        !isTrainings &&
                        !isVolunteers &&
                        !isPublications &&
                        !isReferences && (
                            <Box
                                id='modal-description'
                                sx={{ mt: 2, mb: 2 }}>
                                {names?.map((name) => {
                                    if (name.includes('.label')) return
                                    return (
                                        <Stack
                                            key={name}
                                            spacing={2}
                                            direction='column'>
                                            {name.includes('.description') ? (
                                                <QuillEditor
                                                    value={getValues(name) || `${capitalizeFirstLetter(name.split('.description')[0])} Description`}
                                                    onChange={(value) => setValue(name, value)}
                                                    sx={{ marginTop: 2 }}
                                                    placeholder={getLabelDefault(`${name.split('.description')[0]}CardDefaultDescription`)}
                                                />
                                            ) : (
                                                <PersonalInfoEditModalComponent
                                                    name={name}
                                                    label={toReadableString(name)}
                                                />
                                            )}
                                        </Stack>
                                    )
                                })}
                            </Box>
                        )}

                    <Divider />
                    {loading && (
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            sx={{ mt: 2 }}>
                            <CircularProgress size={24} />
                            <Typography sx={{ ml: 2 }}>Saving...</Typography>
                        </Stack>
                    )}

                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        columnGap={1}>
                        <Button
                            type='button'
                            size='small'
                            color='info'
                            onClick={handleCancel}
                            disabled={loading}
                            sx={{ mt: 2 }}>
                            CANCEL
                        </Button>
                        <Button
                            type='button'
                            color='secondary'
                            onClick={() => {
                                handleSaveClick()
                            }}
                            disabled={loading}
                            sx={{ mt: 2 }}>
                            SAVE
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    )
}

export default EditModal
