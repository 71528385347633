import Stack from '@mui/material/Stack'
import React, { ReactNode } from 'react'
import NameComponent from '../components/resume/name/NameComponent'
import { IResume } from '../models/Resume'

import { Box, Typography } from '@mui/material'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import CityStateZipComponent from '../components/resume/contact/CityStateZipComponent'
import ContactComponent from '../components/resume/contact/ContactComponent'

const Resume19 = (resume: IResume): ReactNode => {
    const orderUsedCards = AllDefaultCards.Resume19.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack width='100%'>
            <Stack
                direction='row'
                columnGap={2}
                alignItems='flex-start'
                justifyContent='space-between'
                marginBottom={`${style.SpaceHeader}px`}>
                <Stack
                    direction='row'
                    columnGap={2}>
                    <Box
                        width={60}
                        height={60}
                        border={1}
                        borderColor='#ad1457'
                        bgcolor='#ad1457'>
                        <Typography
                            fontFamily={style[style.NameFontName]}
                            fontSize={16}
                            fontStyle={style.NameFontStyle}
                            fontWeight={style.NameFontWeight}
                            letterSpacing={5}
                            lineHeight={5.5}
                            textAlign='end'
                            color='white'>
                            {resume.personalInfo.firstName[0]}
                            {resume.personalInfo.lastName[0]}
                        </Typography>
                    </Box>
                    <NameComponent nameWidth='100%' />
                </Stack>
                <Stack direction='column'>
                    <CityStateZipComponent />
                    <ContactComponent
                        includeAddress={false}
                        includeIcons={false}
                        includeLabel={false}
                        hideLinkedIn={true}
                    />
                </Stack>
            </Stack>
            {orderUsedCards.map((value: defaultCard) => {
                return <value.cardComponent key={value.cardType} />
            })}
        </Stack>
    )
}

export default Resume19
