import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { AllDefaultCards, ButtonTypes, defaultCard } from 'utils/Util'
import ResumeNameInputText from '../../components/forms/ResumeNameInputText'
import { OrderResumeComponent } from '../../components/OrderResumeComponent'
import { useUpdateResumeField } from '../../hooks/useResume'
import ErrorState from '../../views/ErrorState'
import LoadingState from '../../views/LoadingState'

export interface OrderResumeDialogProps {
    openOrder: boolean
    onCloseOrder: () => void
}

const OrderResumeDialog = (props: OrderResumeDialogProps) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()
    const { openOrder, onCloseOrder } = props
    const [orderUsedCards, setOrderUsedCards] = useState<defaultCard[]>(
        AllDefaultCards[euContext.resume.currentStyle]
            ?.filter((value: defaultCard) => {
                const style = euContext.resume.cardOrder[euContext.resume.currentStyle]
                if (style) {
                    return (style[value.cardType] ?? -1) >= 0
                }
                return false
            })
            .sort((a: defaultCard, b: defaultCard) => {
                return (euContext.resume.cardOrder[euContext.resume.currentStyle][a.cardType] ?? -1) - (euContext.resume.cardOrder[euContext.resume.currentStyle][b.cardType] || -1)
            })
    )

    const orderFreeCards = AllDefaultCards[euContext.resume.currentStyle]
        .filter((value: defaultCard) => {
            const style = euContext.resume.cardOrder[euContext.resume.currentStyle]
            if (style) {
                return (style[value.cardType] ?? -1) === -1
            }
            return true
        })
        .sort((a: defaultCard, b: defaultCard) => {
            return a.title.localeCompare(b.title)
        })

    useEffect(() => {
        setOrderUsedCards(
            AllDefaultCards[euContext.resume.currentStyle]
                .filter((value: defaultCard) => {
                    const style = euContext.resume.cardOrder[euContext.resume.currentStyle]
                    if (style) {
                        return (style[value.cardType] ?? -1) >= 0
                    }
                    return false
                })
                .sort((a: defaultCard, b: defaultCard) => {
                    return (euContext.resume.cardOrder[euContext.resume.currentStyle][a.cardType] ?? -1) - (euContext.resume.cardOrder[euContext.resume.currentStyle][b.cardType] || -1)
                })
        )
    }, [euContext.resume])

    const handleDrag = (index: number, deltaY: number) => {
        const newIndex = index + Math.round(deltaY / 60) // Assume each item has a height of 60px
        if (newIndex >= 0 && newIndex < orderUsedCards.length && newIndex !== index) {
            const updatedCards = [...orderUsedCards]
            const [movedCard] = updatedCards.splice(index, 1)
            updatedCards.splice(newIndex, 0, movedCard)
            setOrderUsedCards(updatedCards)

            const updatedOrder = updatedCards.reduce(
                (acc, card, idx) => {
                    acc[card.cardType] = idx
                    return acc
                },
                {} as Record<string, number>
            )

            const copyResume = { ...euContext.resume }
            copyResume.cardOrder[euContext.resume.currentStyle] = updatedOrder

            updateResumeField(
                { id: copyResume._id, fieldName: `cardOrder.${euContext.resume.currentStyle}`, fieldValue: updatedOrder },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )
        }
    }

    const addCard = (card: defaultCard) => {
        const copyResume = { ...euContext.resume }
        const style = copyResume.currentStyle

        if (!copyResume.cardOrder[style]) {
            copyResume.cardOrder = { ...copyResume.cardOrder, [style]: {} }
        }
        copyResume.cardOrder[style][card.cardType] = orderUsedCards.length

        updateResumeField(
            { id: copyResume._id, fieldName: `cardOrder.${style}.${card.cardType}`, fieldValue: orderUsedCards.length },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const removeCard = (card: defaultCard) => {
        const copyResume = { ...euContext.resume }
        const style = copyResume.currentStyle

        if (!copyResume.cardOrder[style]) {
            copyResume.cardOrder = { ...copyResume.cardOrder, [style]: {} }
        }

        const seqNum = copyResume.cardOrder[style][card.cardType] ?? -1
        orderUsedCards.forEach((value: defaultCard) => {
            if (value.cardType === card.cardType) copyResume.cardOrder[style][value.cardType] = -1
            else if ((copyResume.cardOrder[style][value.cardType] ?? -1) > seqNum) {
                const newSeqNum = (copyResume.cardOrder[style][value.cardType] ?? 0) - 1
                copyResume.cardOrder[style][value.cardType] = newSeqNum
            }
        })

        updateResumeField(
            { id: copyResume._id, fieldName: `cardOrder.${style}`, fieldValue: copyResume.cardOrder[style] },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    if (isPending) {
        return <LoadingState message={'Updating...'} />
    }

    if (isError) {
        return <ErrorState message={'Error updating resume field'} />
    }

    return (
        <Dialog
            onClose={(event, reason) => reason !== 'backdropClick' && onCloseOrder()}
            open={openOrder}
            maxWidth='lg'
            fullWidth>
            <DialogTitle>
                Order Cards
                <IconButton
                    aria-label='close'
                    onClick={onCloseOrder}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <ResumeNameInputText
                    name='resumeName'
                    label='Resume Name'
                    fontFamily='Roboto'
                    variant='outlined'
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <List sx={{ mt: 4 }}>
                    {orderUsedCards.map((value: defaultCard, index: number) => (
                        <Draggable
                            key={index}
                            axis='y'
                            bounds='parent'
                            onStop={(e, data) => handleDrag(index, data.y)}>
                            <ListItem sx={{ mb: 2, cursor: 'grab' }}>
                                <OrderResumeComponent
                                    card={value}
                                    type={ButtonTypes.Remove}
                                    buttonClicked={() => removeCard(value)}
                                />
                            </ListItem>
                        </Draggable>
                    ))}
                </List>
                {orderFreeCards.map((value: defaultCard, index: number) => (
                    <OrderResumeComponent
                        card={value}
                        type={ButtonTypes.Add}
                        buttonClicked={() => addCard(value)}
                        key={100 + index}
                    />
                ))}
            </DialogContent>
        </Dialog>
    )
}

export default OrderResumeDialog
