import { Box, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../../../context/EmploymentUniverseContext'
import { IEmployment } from '../../../models/Employment'
import { EmploymentUniverseType } from '../../../types/EmploymentUniverseType'
import EditModal from '../../EditModal/EditModal'
import { HorizontalLine } from '../misc/MiscComponents'

interface Props {
    sectionBgColor?: string
}

const LicensesListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedLicense: IEmployment[] = euContext.resume.licenses.license.sort((a, b) => b.startYear - a.startYear)

    const [isModalOpen, setModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number | null>(null)

    const openEditModal = (index: number) => {
        setCurrentIndex(index)
        setModalOpen(true)
    }

    const closeEditModal = () => {
        setModalOpen(false)
        setCurrentIndex(null)
    }

    return (
        <Stack marginBottom={`${style.SpaceCard}px`}>
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                color={style?.SectionFontColor ?? 'black'}
                letterSpacing={style?.SectionFontLetterSpacing ?? 1}
                marginBottom={style.SectionHRLine ? '3px' : `${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {euContext.resume.licenses.label}
            </Typography>
            {style.SectionHRLine && (
                <HorizontalLine
                    bgColor={style.SectionHRColor}
                    mb={style.SpaceSectionTitle}
                    mt={1}
                />
            )}
            <Stack
                direction='column'
                rowGap={1}>
                {orderedLicense.map((emp: IEmployment, index: number) => (
                    <Box
                        key={index}
                        onClick={() => openEditModal(index)}
                        sx={{ cursor: 'pointer' }}>
                        <Stack direction='column'>
                            <Typography
                                fontFamily={style[style.CompanyFontName]}
                                fontSize={`${style.CompanyFontSize}px`}
                                fontStyle={style.CompanyFontStyle}
                                fontWeight={style.CompanyFontWeight}
                                color={style?.CompanyFontColor ?? 'black'}
                                letterSpacing={style?.CompanyFontLetterSpacing ?? 1}
                                textAlign={style.CompanyAlign}>
                                {emp.companyName}&nbsp;-&nbsp;{emp.title}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                color={style?.BodyFontColor ?? 'black'}
                                letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                                textAlign={style.BodyAlign}>
                                {emp.summary.description || ''}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                color={style?.BodyFontColor ?? 'black'}
                                letterSpacing={style?.BodyFontLetterSpacing ?? 1}
                                textAlign={style.BodyAlign}>
                                {format(new Date(emp.startYear, emp.startMonth - 1), style.DateFormat)}
                            </Typography>
                        </Stack>
                    </Box>
                ))}
            </Stack>
            {currentIndex !== null && (
                <EditModal
                    open={isModalOpen}
                    onClose={closeEditModal}
                    isLicenses={true}
                />
            )}
        </Stack>
    )
}

export default LicensesListComponent
