import { Button, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useClientUsers } from '../../hooks/useClients'
import { useArchiveUser } from '../../hooks/useUser'
import { getLabel } from '../../labels/labelUtils'
import LoadingState from '../../views/LoadingState'
import CustomTable from '../CustomTable'
const columns = [
    { id: 'email', label: 'Email' },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'isAdmin', label: 'isAdmin' },
    { id: 'createDate', label: 'Create Date' },
    { id: 'lastAccessDate', label: 'Last Access Date' },
    { id: 'archive', label: 'Archive' }
]

const ActiveClientUsers = () => {
    const euContext = useContext(EmploymentUniverseContext)
    const { data: clientUsersResponse, isLoading } = useClientUsers(euContext.domain)
    const { mutate: archiveUser } = useArchiveUser()

    const handleArchiveUser = (id: string) => {
        archiveUser(id)
    }

    if (isLoading) return <LoadingState />

    const rows = clientUsersResponse?.users.map((user) => ({
        ...user,
        createDate: moment(user.createDate?.toLocaleString()).format('lll'),
        lastAccessDate: `${moment(user.lastAccessDate?.toLocaleString()).fromNow(true)} ago`,
        archive: (
            <Button
                variant='contained'
                sx={{
                    backgroundColor: euContext.htmlColors?.card.buttonBackgroundColor,
                    color: euContext.htmlColors?.card.buttonTextColor,
                    borderColor: euContext.htmlColors?.card.borderBackgroundColor,
                    '&:hover': {
                        backgroundColor: euContext.htmlColors?.card.hoverBackgroundColor,
                        color: euContext.htmlColors?.card.hoverTextColor
                    }
                }}
                onClick={() => handleArchiveUser(user._id)}>
                Archive
            </Button>
        )
    }))

    return (
        <Stack>
            <Typography
                variant='h3'
                align='center'>
                {getLabel('activeUsers')}
            </Typography>
            <CustomTable
                columns={columns}
                rows={rows}
            />
            <Typography align='right'>Total: {clientUsersResponse?.users.length}</Typography>
        </Stack>
    )
}

export default ActiveClientUsers
