import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import ApprovalIcon from '@mui/icons-material/Approval'
import BoltIcon from '@mui/icons-material/Bolt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import HandshakeIcon from '@mui/icons-material/Handshake'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront'
import PsychologyIcon from '@mui/icons-material/Psychology'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TrainIcon from '@mui/icons-material/Train'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WorkIcon from '@mui/icons-material/Work'
import AffiliationsComponent from '../components/resume/affiliations/AffiliationsComponent'
import EducationCondensedListComponent from '../components/resume/educations/EducationCondensedListComponent'
import EducationCondensedSummaryComponent from '../components/resume/educations/EducationCondensedSummaryComponent'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import EducationsComponent from '../components/resume/educations/EducationsComponent'
import EmploymentCondensedListComponent from '../components/resume/employments/EmploymentCondensedListComponent'
import EmploymentsComponent from '../components/resume/employments/EmploymentsComponent'
import HonorsComponent from '../components/resume/honors/HonorsComponent'
import LicensesListComponent from '../components/resume/licenses/LicensesListComponent'
import ProjectsComponent from '../components/resume/projects/ProjectsComponent'
import PublicationsComponent from '../components/resume/publications/PublicationsComponent'
import ReferencesComponent from '../components/resume/references/ReferencesComponent'
import ObjectiveComponent from '../components/resume/simpleCards/ObjectiveComponent'
import OtherComponent from '../components/resume/simpleCards/OtherComponent'
import PersonalComponent from '../components/resume/simpleCards/PersonalComponent'
import ProfessionalComponent from '../components/resume/simpleCards/ProfessionalComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import AccomplishmentsBulletListComponent from '../components/resume/simpleLists/AccomplishmentsBulletListComponent'
import AccomplishmentsListComponent from '../components/resume/simpleLists/AccomplishmentsListComponent'
import CapabilitiesBulletListComponent from '../components/resume/simpleLists/CapabilitiesBulletListComponent'
import CapabilitiesListComponent from '../components/resume/simpleLists/CapabilitiesListComponent'
import SkillsBubbleListComponent from '../components/resume/simpleLists/SkillsBubbleListComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import SkillsCircularProgressComponent from '../components/resume/simpleLists/SkillsCircularProgressComponent'
import SkillsHorizontalListComponent from '../components/resume/simpleLists/SkillsHorizontalListComponent'
import SkillsRateLineComponent from '../components/resume/simpleLists/SkillsRateLineComponent'
import TalentsComponent from '../components/resume/talents/TalentsComponent'
import TrainingListComponent from '../components/resume/trainings/TrainingListComponent'
import VolunteersComponent from '../components/resume/volunteers/VolunteersComponent'
import { cardTypes } from './Constants'
import { defaultCard } from './Util'

export const DefaultDefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume1DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume2DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'skills', icon: StarIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume3DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsCircularProgressComponent, description: 'skills', icon: StarIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume4DefaultCards: defaultCard[] = [
    {
        title: 'accomplishments',
        cardType: cardTypes.accomplishments,
        cardComponent: AccomplishmentsListComponent,
        description: 'listOfAccomplishments',
        icon: EmojiEventsIcon
    },
    {
        title: 'affiliations',
        cardType: cardTypes.affiliations,
        cardComponent: AffiliationsComponent,
        description: 'listOfAffiliations',
        icon: HandshakeIcon
    },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    {
        title: 'employments',
        cardType: cardTypes.employments,
        cardComponent: EmploymentsComponent,
        isDraggable: true,
        description: 'listOfEmployments',
        icon: WorkIcon
    },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Professional Profile', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume5DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsCircularProgressComponent, description: 'skills', icon: StarIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, isDraggable: true, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume6DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsBulletListComponent, description: 'Summary Qualifications', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesBulletListComponent, description: 'Professional Skills', icon: BoltIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary Statement', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume7DefaultCards: defaultCard[] = [
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume8DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'Work Experience', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Profile', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume9DefaultCards: defaultCard[] = [
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume10DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationCondensedListComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume11DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume12DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume13DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsRateLineComponent, description: 'skills', icon: StarIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume14DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume15DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesBulletListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationCondensedSummaryComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsHorizontalListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume16DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume17DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsBulletListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationCondensedSummaryComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentCondensedListComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'certifications', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'certifications', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume18DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume19DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationListComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const Resume20DefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]

export const SingleColumnDefaultCards: defaultCard[] = [
    { title: 'accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'listOfAccomplishments', icon: EmojiEventsIcon },
    { title: 'affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsComponent, description: 'listOfAffiliations', icon: HandshakeIcon },
    { title: 'capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'listOfCapabilities', icon: BoltIcon },
    { title: 'educations', cardType: cardTypes.educations, cardComponent: EducationsComponent, description: 'listOfEducations', icon: SchoolIcon },
    { title: 'employments', cardType: cardTypes.employments, cardComponent: EmploymentsComponent, description: 'listOfEmployments', icon: WorkIcon },
    { title: 'honors', cardType: cardTypes.honors, cardComponent: HonorsComponent, description: 'listOfHonors', icon: EmojiEventsIcon },
    { title: 'licenses', cardType: cardTypes.licenses, cardComponent: LicensesListComponent, description: 'listOfLicenses', icon: ApprovalIcon },
    { title: 'objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'objective', icon: TrackChangesIcon },
    { title: 'other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'other', icon: AltRouteIcon },
    { title: 'personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'personal', icon: PermIdentityIcon },
    { title: 'projects', cardType: cardTypes.projects, cardComponent: ProjectsComponent, description: 'projects', icon: AccountTreeIcon },
    { title: 'professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'professional', icon: AccountTreeIcon },
    { title: 'publications', cardType: cardTypes.publications, cardComponent: PublicationsComponent, description: 'publications', icon: NewspaperIcon },
    { title: 'references', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'references', icon: PsychologyIcon },
    { title: 'skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'skills', icon: StarIcon },
    { title: 'summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'summary', icon: SummarizeIcon },
    { title: 'talents', cardType: cardTypes.talents, cardComponent: TalentsComponent, description: 'talents', icon: PhotoCameraFrontIcon },
    { title: 'trainings', cardType: cardTypes.trainings, cardComponent: TrainingListComponent, description: 'trainings', icon: TrainIcon },
    { title: 'volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersComponent, description: 'volunteers', icon: VolunteerActivismIcon }
]
