import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Stripe } from 'stripe'
import axios from '../config/axiosConfig'
import { IClients, IDefaultText } from '../models/Clients'
import { IHTMLColorName } from '../models/HTMLColors'
import { IUser } from '../models/User'
import {
    checkoutSessionLink,
    clientColorLink,
    clientExistLink,
    clientLink,
    clientsLink,
    clientUsersLink,
    getPaymentMethodsLink,
    getStripePaymentsLink,
    restoreGetUsersLink,
    sessionStatusLink
} from '../utils/Constants'

interface IClientsResponse {
    status: string
    clients: IClients[]
}

interface IClientResponse {
    status: string
    client: IClients
}

interface IClientUsersResponse {
    status: string
    users: IUser[]
}

interface IClientExistsResponse {
    status: string
    exists: boolean
    htmlColors: IHTMLColorName
    defaultText: IDefaultText
}

interface IClientSessionStatusResponse {
    status: string
    customer_email: string
}

const fetchClients = async (): Promise<IClientsResponse> => {
    const response = await axios.get<IClientsResponse>(clientsLink)
    return response.data
}

const fetchClient = async (id: string): Promise<IClientResponse> => {
    const response = await axios.get<IClientResponse>(clientLink(id))
    return response.data
}

const fetchClientUsers = async (subdomain: string): Promise<IClientUsersResponse> => {
    const response = await axios.get<IClientUsersResponse>(clientUsersLink(subdomain))
    return response.data
}

const fetchArchivedClientUsers = async (subdomain: string): Promise<IClientUsersResponse> => {
    const response = await axios.get<IClientUsersResponse>(restoreGetUsersLink(subdomain))
    return response.data
}

const doesClientExist = async (subdomain: string): Promise<IClientExistsResponse> => {
    const response = await axios.get<IClientExistsResponse>(clientExistLink(subdomain))
    return response.data
}

const addClient = async (client: IClients) => {
    if (client._id) {
        const response = await axios.patch(clientLink(client._id), { client })
        return response.data
    } else {
        const response = await axios.post(clientsLink, { client })
        return response.data
    }
}

const removeClient = async (client: IClients) => {
    const response = await axios.delete(clientLink(client._id))
    return response.data
}

const updateClient = async (client: IClients) => {
    const response = await axios.patch(clientLink(client._id), { client })
    return response.data
}

const fetchPaymentMethods = async (customerId: string) => {
    const response = await axios.get(getPaymentMethodsLink(customerId))
    return response.data
}

const fetchStripePayments = async () => {
    const response = await axios.get<Stripe.PaymentIntent[]>(getStripePaymentsLink)
    return response.data
}

const createStripeSession = async () => {
    const response = await axios.post(checkoutSessionLink)
    return response.data
}

const checkStripeSessionStatus = async (sessionId: string) => {
    const response = await axios.get(sessionStatusLink(sessionId))
    return response.data
}

const fetchClientColors = async (subdomain: string) => {
    const url = clientColorLink(subdomain)
    const response = await axios.get(url)
    return response.data
}

const updateClientColor = async (subdomain: string, htmlColors: IHTMLColorName | undefined) => {
    const url = clientColorLink(subdomain)
    const response = await axios.patch(url, { htmlColors })
    return response.data
}

export const useUpdateClientColor = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ subdomain, htmlColors }: { subdomain: string; htmlColors: IHTMLColorName | undefined }) => updateClientColor(subdomain, htmlColors),
        onError: (error) => {
            console.error('Error updating client colors:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clients'] })
            return data
        }
    })
}

export const useClientColors = (subdomain: string) => {
    return useQuery({
        queryKey: ['clients', subdomain],
        queryFn: () => fetchClientColors(subdomain)
    })
}

export const useClients = () => {
    return useQuery<IClientsResponse>({
        queryKey: ['clients'],
        queryFn: () => fetchClients()
    })
}

export const useClient = (id: string) => {
    return useQuery<IClientResponse>({
        queryKey: ['client', id],
        queryFn: () => fetchClient(id)
    })
}

export const useClientUsers = (subdomain: string) => {
    return useQuery<IClientUsersResponse>({
        queryKey: ['clientUsers', subdomain],
        queryFn: () => fetchClientUsers(subdomain)
    })
}

export const useArchivedClientUsers = (subdomain: string) => {
    return useQuery<IClientUsersResponse>({
        queryKey: ['archivedClientUsers', subdomain],
        queryFn: () => fetchArchivedClientUsers(subdomain)
    })
}

export const useDoesClientExist = (subdomain: string) => {
    return useQuery<IClientExistsResponse>({
        queryKey: ['doesClientExist', subdomain],
        queryFn: () => doesClientExist(subdomain)
    })
}

export const useAddClient = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (client: IClients) => addClient(client),
        onError: (error) => {
            console.error('Error adding/updating client:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clients'] })
            return data
        }
    })
}

export const useUpdateClient = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (client: IClients) => updateClient(client),
        onError: (error) => {
            console.error('Error adding/updating client:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clients'] })
            return data
        }
    })
}

export const useRemoveClient = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (client: IClients) => removeClient(client),
        onError: (error) => {
            console.error('Error removing client:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['clients'] })
            return data
        }
    })
}

export const useCreateStripeSession = () => {
    return useMutation({
        mutationFn: () => createStripeSession(),
        onError: (error) => {
            console.error('Error creating stripe session:', error)
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useCheckStripeSessionStatus = (sessionId: string) => {
    return useQuery<IClientSessionStatusResponse>({
        queryKey: ['sessionStatus', sessionId],
        queryFn: () => checkStripeSessionStatus(sessionId)
    })
}

export const useStripePayments = () => {
    return useQuery<Stripe.PaymentIntent[]>({
        queryKey: ['stripePayments'],
        queryFn: () => fetchStripePayments()
    })
}

export const usePaymentMethods = (customerId: string) => {
    return useQuery({ queryKey: ['paymentMethods', customerId], queryFn: () => fetchPaymentMethods(customerId) })
}
