import React, { createContext, useState } from 'react'
import { IDefaultText } from '../models/Clients'
import { IHTMLColorName } from '../models/HTMLColors'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

interface IProtectedEmploymentUniverse {
    children: JSX.Element
}

const EmploymentUniverseContext = createContext<EmploymentUniverseType>({
    domain: 'Loading',
    setDomain: () => {},
    user: {} as IUser,
    setUser: () => {},
    resume: {} as IResume,
    setResume: () => {},
    htmlColors: undefined,
    setHtmlColors: () => {},
    defaultText: undefined,
    setDefaultText: () => {}
})

const EmploymentUniverseProvider = (props: IProtectedEmploymentUniverse) => {
    const [user, setUser] = useState<IUser>({
        _id: undefined,
        email: '',
        firstName: '',
        lastName: '',
        profilePic: '',
        isAdmin: false,
        isSuperAdmin: false,
        client_id: '',
        password: '',
        linkedInSub: '',
        createDate: undefined,
        lastAccessDate: undefined
    })

    const [resume, setResume] = useState(() => {
        return {
            _id: '',
            resumeName: ''
        } as IResume
    })

    const [domain, setDomain] = useState('Loading')

    const [htmlColors, setHtmlColors] = useState<IHTMLColorName | undefined>(undefined)

    const [defaultText, setDefaultText] = useState<IDefaultText | undefined>(undefined)

    return (
        <EmploymentUniverseContext.Provider value={{ domain, setDomain, user, setUser, resume, setResume, htmlColors, setHtmlColors, defaultText, setDefaultText }}>
            {props.children}
        </EmploymentUniverseContext.Provider>
    )
}

export { EmploymentUniverseContext, EmploymentUniverseProvider }
