/* eslint-disable @typescript-eslint/no-var-requires */
import { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const getLabel = (label: string) => {
    try {
        const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

        if (euContext.defaultText) {
            return euContext.defaultText[label] || 'No defined label'
        }
        return 'No defined label2'
    } catch (e) {
        return 'No defined label3'
    }
}
