import { Button, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { useArchivedClientUsers } from '../../hooks/useClients'
import { useRestoreUser } from '../../hooks/useUser'
import { getLabel } from '../../labels/labelUtils'
import LoadingState from '../../views/LoadingState'
import CustomTable from '../CustomTable'

const columns = [
    { id: 'email', label: 'Email' },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'isAdmin', label: 'isAdmin' },
    { id: 'createDate', label: 'Create Date' },
    { id: 'lastAccessDate', label: 'Last Access Date' },
    { id: 'restore', label: 'Restore' }
]

const ArchivedClientUsers = () => {
    const euContext = useContext(EmploymentUniverseContext)
    const { data: clientArchivedUsersResponse, isLoading } = useArchivedClientUsers(euContext.domain)
    const { mutate: restoreUser } = useRestoreUser()

    const handleRestoreUser = (id: string) => {
        restoreUser(id)
    }

    if (isLoading) return <LoadingState />

    const rows = clientArchivedUsersResponse?.users.map((auser) => ({
        ...auser,
        createDate: moment(auser.createDate?.toLocaleString()).format('lll'),
        lastAccessDate: `${moment(auser.lastAccessDate?.toLocaleString()).fromNow(true)} ago`,
        restore: (
            <Button
                variant='contained'
                sx={{
                    backgroundColor: euContext.htmlColors?.card.buttonBackgroundColor,
                    color: euContext.htmlColors?.card.buttonTextColor,
                    borderColor: euContext.htmlColors?.card.borderBackgroundColor,
                    '&:hover': {
                        backgroundColor: euContext.htmlColors?.card.hoverBackgroundColor,
                        color: euContext.htmlColors?.card.hoverTextColor
                    }
                }}
                onClick={() => handleRestoreUser(auser._id)}>
                Restore
            </Button>
        )
    }))

    return (
        <Stack>
            <Typography
                variant='h3'
                align='center'>
                {getLabel('archivedUsers')}
            </Typography>
            <CustomTable
                columns={columns}
                rows={rows}
            />
            <Typography align='right'>Total: {clientArchivedUsersResponse?.users.length}</Typography>
        </Stack>
    )
}

export default ArchivedClientUsers
