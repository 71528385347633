import { makeStyles } from '@material-ui/core/styles'
import { Stack, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import backgroundImage from '../../../images/background/th-2022402106.jpg'
import { IStyles } from '../../../models/Styles'
import { useDynamicFontSize } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'

const useStyles = makeStyles(() => ({
    root: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '30vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer' // Make the background clickable
    }
}))

const NameBackgroundContent = ({ style, firstName, lastName, onClick }: { style: IStyles; firstName: string; lastName: string; onClick?: () => void }) => {
    const classes = useStyles()
    const containerRef = useRef<HTMLDivElement | null>(null)
    const firstNameFontSize = useDynamicFontSize(`${firstName} `, containerRef, style?.NameFontSize, 10, 8)
    const lastNameFontSize = useDynamicFontSize(`${lastName}`, containerRef, style?.NameFontSize, 10, 8)
    const fontSize = Math.min(firstNameFontSize, lastNameFontSize)
    return (
        <Stack
            className={classes.root}
            onClick={onClick}
            ref={containerRef}
            sx={{
                textAlign: style?.NameAlign ?? 'center',
                textTransform: style?.NameFontCase ?? 'none'
            }}>
            <Typography
                margin='none'
                fontFamily={style[style.NameFontName]}
                fontSize={`${fontSize}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                color={style?.NameFontColor ?? 'black'}
                letterSpacing={`${style?.NameFontLetterSpacing ?? 0}px`}
                textTransform={style?.NameFontCase ?? 'none'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                marginRight={2} // for the space between names
                sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'normal'
                }}>
                {firstName}
            </Typography>
            <Typography
                margin='none'
                fontFamily={style[style.NameFontName]}
                fontSize={`${fontSize}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                color={style?.NameFontColor ?? 'black'}
                letterSpacing={`${style?.NameFontLetterSpacing ?? 0}px`}
                textTransform={style?.NameFontCase ?? 'none'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'normal'
                }}>
                {lastName}
            </Typography>
        </Stack>
    )
}

export const NameComponentWithBackground = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const nameRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const names = ['personalInfo.firstName', 'personalInfo.lastName']
    const firstName = euContext.resume.personalInfo.firstName
    const lastName = euContext.resume.personalInfo.lastName

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={nameRef}>
                <NameBackgroundContent
                    style={style}
                    firstName={firstName}
                    lastName={lastName}
                    onClick={handleOpenModal}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </>
    )
}
