import { Box, Grid2, Stack } from '@mui/material'
import React from 'react'
import EducationListComponent from '../components/resume/educations/EducationListComponent'
import NameVerticleComponent from '../components/resume/name/NameVerticleComponent'
import PictureRectangle from '../components/resume/picture/PictureRectangle'
import ReferencesComponent from '../components/resume/references/ReferencesComponent'
import SummaryComponent from '../components/resume/simpleCards/SummaryComponent'
import SkillsBulletListComponent from '../components/resume/simpleLists/SkillsBulletListComponent'
import TalentsComponent from '../components/resume/talents/TalentsComponent'
import TitleComponent from '../components/resume/title/TitleComponent'
import { IResume } from '../models/Resume'
import { AllDefaultCards, defaultCard } from '../utils/Util'

const Resume5 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume5?.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })
    return (
        <Box
            sx={{
                width: '800px',
                margin: 'auto',
                borderRadius: 3,
                backgroundColor: '#ececec'
            }}>
            <Grid2
                sx={{ width: '100%' }}
                container>
                <Grid2 sx={{ width: '100%' }}>
                    <Stack
                        sx={{ width: '100%', paddingLeft: '20px', paddingRight: '20px' }}
                        spacing={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                paddingRight: '20px',
                                paddingLeft: '20px',
                                paddingTop: '50px',
                                paddingBottom: '20px',
                                gap: 4
                            }}>
                            <PictureRectangle
                                picWidth={305}
                                picHeight={225}
                                picAlign='center'
                                picMT={0}
                            />

                            <Box sx={{ paddingTop: 1, width: '500px' }}>
                                <NameVerticleComponent
                                    mt={3}
                                    mb={3}
                                    sizeOffset={15}
                                />
                                <TitleComponent />
                                <SummaryComponent />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                            <Stack
                                sx={{ width: '40%' }}
                                spacing={3}>
                                <EducationListComponent />
                                <SkillsBulletListComponent />
                                <TalentsComponent />
                            </Stack>
                            <Stack
                                sx={{ width: '50%' }}
                                spacing={3}>
                                <Box>
                                    <Stack
                                        direction='column'
                                        sx={{ gap: '12px' }}>
                                        {orderUsedCards.map((value: defaultCard) => {
                                            return <value.cardComponent key={value.cardType} />
                                        })}
                                    </Stack>
                                    <ReferencesComponent />
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default Resume5
