import { Box, Paper } from '@mui/material'
import 'QuillEditor.css'
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const quillModules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean']
    ]
}

interface QuillEditorProps {
    value: string
    onChange: (value: string) => void
    sx?: object
    placeholder?: string
}

const QuillEditor = ({ value, onChange, sx, placeholder = '' }: QuillEditorProps) => {
    return (
        <Paper
            elevation={1}
            sx={{
                height: 250,
                display: 'flex',
                flexDirection: 'column',
                ...sx
            }}>
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    borderTop: 'none',
                    height: '100%'
                }}>
                <ReactQuill
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    theme='snow'
                    modules={quillModules}
                    style={{ height: '100%' }}
                />
            </Box>
        </Paper>
    )
}

export default QuillEditor
