import { IStyles } from '../models/Styles'
import Colorado from '../resumeStyles/ColoradoStyle'
import Default from '../resumeStyles/DefaultStyle'
import Harvard from '../resumeStyles/HarvardStyle'
import Miami from '../resumeStyles/MiamiStyle'
import Resume10 from '../resumeStyles/Resume10Style'
import Resume11 from '../resumeStyles/Resume11Style'
import Resume12 from '../resumeStyles/Resume12Style'
import Resume13 from '../resumeStyles/Resume13Style'
import Resume14 from '../resumeStyles/Resume14Style'
import Resume15 from '../resumeStyles/Resume15Style'
import Resume16 from '../resumeStyles/Resume16Style'
import Resume17 from '../resumeStyles/Resume17Style'
import Resume18 from '../resumeStyles/Resume18Style'
import Resume19 from '../resumeStyles/Resume19Style'
import Resume1 from '../resumeStyles/Resume1Style'
import Resume20 from '../resumeStyles/Resume20Style'
import Resume2 from '../resumeStyles/Resume2Style'
import Resume3 from '../resumeStyles/Resume3Style'
import Resume4 from '../resumeStyles/Resume4Style'
import Resume5 from '../resumeStyles/Resume5Style'
import Resume6 from '../resumeStyles/Resume6Style'
import Resume7 from '../resumeStyles/Resume7Style'
import Resume8 from '../resumeStyles/Resume8Style'
import Resume9 from '../resumeStyles/Resume9Style'

const usersLink = '/api/v1/users'
const userLink = (id: string) => `${usersLink}/${id}`
const loginApiLink = `${usersLink}/login`
const loginGoogleLink = `${usersLink}/loginGoogle`
const loginLinkedInLink = `${usersLink}/loginLinkedIn`
const logoutLink = `${usersLink}/logout`
const registerLink = `${usersLink}/register`
const registerLinkedInLink = `${usersLink}/registerLinkedIn`
const registerGoogleLink = `${usersLink}/registerGoogle`
const restoreLink = '/api/v1/restore'
const restoreUsersLink = (id: string) => `${restoreLink}/${id}`
const restoreGetUsersLink = (subdomain: string) => `${restoreLink}/${subdomain}`
const resumeInfoLink = (userId: string) => `${usersLink}/${userId}/resumeInfos`
const aiLink = '/api/v1/ai'
const askOpenAILink = `${aiLink}/askOpenAI`
const askGoogleAILink = `${aiLink}/askGoogleAI`
const resumesLink = '/api/v1/resumes'
const importResumeLink = `${resumesLink}/import`
const viewResumeLink = (domain: string, firstName: string, lastName: string, resumeName: string, location: string, state: string) =>
    `/api/v1/view/${domain}/${firstName}/${lastName}/${resumeName}/${location}/${state}`
const resumeLink = (id: string) => `${resumesLink}/${id}`
const checkIfPublicLink = (id: string) => `${resumeLink(id)}/public`
const affiliationLink = (id: string) => `${resumeLink(id)}/affiliations`
const educationLink = (id: string) => `${resumeLink(id)}/educations`
const employmentLink = (id: string) => `${resumeLink(id)}/employments`
const honorLink = (id: string) => `${resumeLink(id)}/honors`
const licenseLink = (id: string) => `${resumeLink(id)}/licenses`
const projectLink = (id: string) => `${resumeLink(id)}/projects`
const publicationLink = (id: string) => `${resumeLink(id)}/publications`
const referenceLink = (id: string) => `${resumeLink(id)}/references`
const talentLink = (id: string) => `${resumeLink(id)}/talents`
const trainingLink = (id: string) => `${resumeLink(id)}/trainings`
const volunteerLink = (id: string) => `${resumeLink(id)}/volunteers`
const updatePictureLink = (id: string) => `${resumeLink(id)}/updatePicture`
const clientsLink = '/api/v1/clients'
const stripeLink = '/api/v1/stripe'
const checkoutSessionLink = `${stripeLink}/create-checkout-session`
const sessionStatusLink = (sessionId: string) => `${stripeLink}/session-status?session_id=${sessionId}`
const clientLink = (id: string) => `${clientsLink}/${id}`
const environmentLink = '/api/v1/environment'
const clientExistLink = (subdomian: string) => `${environmentLink}/${subdomian}/exists`
const clientColorLink = (subdomian: string) => `${clientsLink}/${subdomian}/htmlColors`
const clientUsersLink = (subdomain: string) => `${clientsLink}/${subdomain}/users`
const statementsLink = '/api/v1/statements'
const statementLink = (id: string) => `${statementsLink}/${id}`
const getStripePaymentsLink = `${stripeLink}/payments`
const getPaymentMethodsLink = (customerId: string) => `${stripeLink}/payment-methods/${customerId}`
const getDistinctStatementCategoriesLink = `${statementsLink}/categories`
const getDistinctStatementJobTitlesLink = (category: string) => `${getDistinctStatementCategoriesLink}/${category}`
const getStatementsByCategoryJobTitleLink = (category: string, jobTitle: string) => `${getDistinctStatementCategoriesLink}/${category}/jobTitles/${jobTitle}`
const tokensLink = '/api/v1/tokens'
const tokenRefreshLink = `${tokensLink}/refresh`

export {
    affiliationLink,
    askGoogleAILink,
    askOpenAILink,
    checkIfPublicLink,
    checkoutSessionLink,
    clientColorLink,
    clientExistLink,
    clientLink,
    clientsLink,
    clientUsersLink,
    educationLink,
    employmentLink,
    environmentLink,
    getDistinctStatementCategoriesLink,
    getDistinctStatementJobTitlesLink,
    getPaymentMethodsLink,
    getStatementsByCategoryJobTitleLink,
    getStripePaymentsLink,
    honorLink,
    importResumeLink,
    licenseLink,
    loginApiLink,
    loginGoogleLink,
    loginLinkedInLink,
    logoutLink,
    projectLink,
    publicationLink,
    referenceLink,
    registerGoogleLink,
    registerLink,
    registerLinkedInLink,
    restoreGetUsersLink,
    restoreUsersLink,
    resumeInfoLink,
    resumeLink,
    resumesLink,
    sessionStatusLink,
    statementLink,
    statementsLink,
    talentLink,
    tokenRefreshLink,
    tokensLink,
    trainingLink,
    updatePictureLink,
    userLink,
    usersLink,
    viewResumeLink,
    volunteerLink
}

export const MAX_RESUMES = 25

export const notAddable = -2
export const notShown = -1

export enum cardTypes {
    accomplishments = 'accomplishments',
    affiliations = 'affiliations',
    capabilities = 'capabilities',
    educations = 'educations',
    employments = 'employments',
    honors = 'honors',
    licenses = 'licenses',
    objective = 'objective',
    other = 'other',
    personal = 'personal',
    projects = 'projects',
    professional = 'professional',
    publications = 'publications',
    references = 'references',
    skills = 'skills',
    summary = 'summary',
    talents = 'talents',
    trainings = 'trainings',
    volunteers = 'volunteers'
}

export type DefaultSeqNumPerStyleDetailType = {
    [card in cardTypes]?: number
}

export enum validResumeStyles {
    Default = 'Default',
    Colorado = 'Colorado',
    Harvard = 'Harvard',
    Miami = 'Miami',
    Resume1 = 'Resume1',
    Resume2 = 'Resume2',
    Resume3 = 'Resume3',
    Resume4 = 'Resume4',
    Resume5 = 'Resume5',
    Resume6 = 'Resume6',
    Resume7 = 'Resume7',
    Resume8 = 'Resume8',
    Resume9 = 'Resume9',
    Resume10 = 'Resume10',
    Resume11 = 'Resume11',
    Resume12 = 'Resume12',
    Resume13 = 'Resume13',
    Resume14 = 'Resume14',
    Resume15 = 'Resume15',
    Resume16 = 'Resume16',
    Resume17 = 'Resume17',
    Resume18 = 'Resume18',
    Resume19 = 'Resume19',
    Resume20 = 'Resume20'
}

export type DefaultSeqNumPerStyleType = {
    [style in validResumeStyles]: DefaultSeqNumPerStyleDetailType
}

// -2 do not ever show, -1 not visible but can show, positive number is order or card
export const DefaultSeqNumPerStyle: DefaultSeqNumPerStyleType = {
    Colorado: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Harvard: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Miami: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Default: { employments: 0, educations: 1, summary: notAddable, skills: 2 },
    Resume1: { employments: 1, educations: 0, summary: notAddable, skills: notAddable },
    Resume2: { employments: 1, educations: notAddable, summary: notAddable, skills: 0 },
    Resume3: { employments: 1, educations: notAddable, summary: notAddable, skills: 0 },
    Resume4: { employments: 1, educations: notAddable, summary: 0, skills: notAddable },
    Resume5: { employments: 1, educations: notAddable, summary: 0, skills: notAddable },
    Resume6: { skills: notAddable, employments: notAddable, educations: notAddable, licenses: notAddable, summary: 0, accomplishments: 1, capabilities: 2 },
    Resume7: { summary: 0, employments: 1, educations: 2, references: 3, skills: notAddable, trainings: notAddable, accomplishments: notAddable },
    Resume8: { educations: notAddable, skills: notAddable, capabilities: notAddable, other: notAddable, summary: 0, employments: 1 },
    Resume9: { employments: 1, educations: notAddable, summary: 0, skills: notAddable, accomplishments: notAddable, trainings: notAddable },
    Resume10: { employments: 1, educations: 2, projects: 3, summary: 0, skills: notAddable, trainings: notAddable },
    Resume11: { employments: 0, educations: notAddable, summary: notAddable, skills: notAddable, other: notAddable },
    Resume12: { summary: 0, employments: 1, educations: notAddable, skills: notAddable },
    Resume13: { employments: 0, skills: 1, summary: notAddable, educations: notAddable, capabilities: notAddable, trainings: notAddable },
    Resume14: { summary: 0, employments: 1, educations: notAddable, skills: notAddable },
    Resume15: { summary: 0, employments: 1, educations: 2, skills: 3, capabilities: 4 },
    Resume16: { summary: 0, employments: 1 },
    Resume17: { summary: 0, employments: 3, educations: 4, skills: 2, accomplishments: 1, professional: 5 },
    Resume18: { employments: 0, educations: notAddable, skills: notAddable, other: notAddable },
    Resume19: { summary: 0, employments: 1, educations: 3, skills: 2 },
    Resume20: { summary: 0, employments: 1, educations: notAddable, skills: notAddable }
}

export type DefaultMaxSeqNumPerStyleType = {
    [style in validResumeStyles]: number
}

export const DefaultMaxSeqNumPerStyle: DefaultMaxSeqNumPerStyleType = {
    Colorado: 3,
    Harvard: 3,
    Miami: 3,
    Default: 2,
    Resume1: 1,
    Resume2: 1,
    Resume3: 1,
    Resume4: 1,
    Resume5: 1,
    Resume6: 2,
    Resume7: 3,
    Resume8: 1,
    Resume9: 1,
    Resume10: 3,
    Resume11: 0,
    Resume12: 1,
    Resume13: 1,
    Resume14: 1,
    Resume15: 4,
    Resume16: 1,
    Resume17: 5,
    Resume18: 0,
    Resume19: 3,
    Resume20: 1
}

export type ResumeStylesType = {
    [style in validResumeStyles]: IStyles
}

export const ResumeStyles: ResumeStylesType = {
    Colorado,
    Harvard,
    Miami,
    Default,
    Resume1,
    Resume2,
    Resume3,
    Resume4,
    Resume5,
    Resume6,
    Resume7,
    Resume8,
    Resume9,
    Resume10,
    Resume11,
    Resume12,
    Resume13,
    Resume14,
    Resume15,
    Resume16,
    Resume17,
    Resume18,
    Resume19,
    Resume20
}
