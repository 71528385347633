import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Checkbox, FormControlLabel, IconButton, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import React from 'react'
import { getLabel } from '../../labels/labelUtils'
import { IEmployment } from '../../models/Employment'
import QuillEditor from '../forms/QuillEditor'

interface EmploymentEditModalComponentProps {
    employmentList: IEmployment[]
    setEmploymentList: React.Dispatch<React.SetStateAction<IEmployment[]>>
    selectedTab: number
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
    listType: string
}

const EmploymentEditModalComponent: React.FC<EmploymentEditModalComponentProps> = ({ employmentList, setEmploymentList, selectedTab, setSelectedTab, listType }) => {
    const handleAddEmployment = () => {
        const newEmployment: IEmployment = {
            title: '',
            companyName: '',
            startYear: new Date().getFullYear(),
            startMonth: 1,
            endYear: new Date().getFullYear(),
            endMonth: 1,
            isPresent: false,
            location: '',
            summary: {
                description: '',
                label: ''
            },
            seqNum: employmentList.length
        }
        setEmploymentList([...employmentList, newEmployment])
        setSelectedTab(employmentList.length)
    }

    const handleDeleteEmployment = (index: number) => {
        const updatedList = employmentList.filter((_, i) => i !== index)
        setEmploymentList(updatedList)
        setSelectedTab(Math.max(0, index - 1))
    }

    const handleEmploymentChange = (index: number, field: keyof IEmployment | 'start' | 'end', value: string | boolean) => {
        setEmploymentList((prevList) =>
            prevList.map((employment, i) => {
                if (i === index) {
                    if (field === 'isPresent' && typeof value === 'boolean') {
                        return { ...employment, isPresent: value }
                    } else if (field === 'start' || field === 'end') {
                        const [year, month] = (value as string).split('-')
                        const updatedYear = parseInt(year, 10)
                        const updatedMonth = parseInt(month, 10)

                        if (field === 'start') {
                            return { ...employment, startYear: updatedYear, startMonth: updatedMonth }
                        } else if (field === 'end') {
                            return { ...employment, endYear: updatedYear, endMonth: updatedMonth }
                        }
                    } else {
                        return { ...employment, [field]: value }
                    }
                }
                return employment
            })
        )
    }

    const handleQuillChange = (index: number, value: string) => {
        setEmploymentList((prevList) => prevList.map((employment, i) => (i === index ? { ...employment, summary: { ...employment.summary, description: value } } : employment)))
    }

    const isPresent = employmentList[selectedTab]?.isPresent || false

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                aria-label={`${listType}-tabs`}
                variant='scrollable'
                scrollButtons='auto'
                sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {employmentList.map((employment, index) => (
                    <Tab
                        key={`tab-${index}`}
                        label={employment.companyName || 'Untitled'}
                    />
                ))}
            </Tabs>

            {employmentList[selectedTab] ? (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Stack
                        spacing={3}
                        direction='row'>
                        <TextField
                            label='Title'
                            variant='outlined'
                            value={employmentList[selectedTab]?.title || ''}
                            onChange={(e) => handleEmploymentChange(selectedTab, 'title', e.target.value)}
                        />
                        <TextField
                            label='Organization Name'
                            variant='outlined'
                            value={employmentList[selectedTab]?.companyName || ''}
                            onChange={(e) => handleEmploymentChange(selectedTab, 'companyName', e.target.value)}
                        />
                        <TextField
                            label='Start Date'
                            type='month'
                            variant='outlined'
                            value={`${employmentList[selectedTab]?.startYear || ''}-${employmentList[selectedTab]?.startMonth?.toString().padStart(2, '0') || ''}`}
                            onChange={(e) => handleEmploymentChange(selectedTab, 'start', e.target.value)}
                        />
                        {!isPresent && (
                            <TextField
                                label='End Date'
                                type='month'
                                variant='outlined'
                                value={`${employmentList[selectedTab]?.endYear || ''}-${employmentList[selectedTab]?.endMonth?.toString().padStart(2, '0') || ''}`}
                                onChange={(e) => handleEmploymentChange(selectedTab, 'end', e.target.value)}
                            />
                        )}
                    </Stack>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPresent}
                                onChange={(e) => handleEmploymentChange(selectedTab, 'isPresent', e.target.checked)}
                            />
                        }
                        label='I am currently working here'
                    />
                    <Box sx={{ mt: 3, mb: 3 }}>
                        <Typography variant='h6'>Job Summary</Typography>
                        <QuillEditor
                            value={employmentList[selectedTab]?.summary?.description || ''}
                            onChange={(value) => handleQuillChange(selectedTab, value)}
                            placeholder={getLabel('summaryCardDefaultDescription')}
                        />
                    </Box>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                        mt={2}>
                        <IconButton
                            onClick={() => handleDeleteEmployment(selectedTab)}
                            color='error'>
                            <DeleteIcon />
                        </IconButton>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleAddEmployment}>
                            Add Employment
                        </Button>
                    </Stack>
                </Box>
            ) : (
                <Typography>No Employment History found</Typography>
            )}
        </Box>
    )
}

export default EmploymentEditModalComponent
