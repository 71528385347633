import { Stack, Typography, type TypographyProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { useContext, useRef, useState } from 'react'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../../../models/Styles'
import { useDynamicFontSize } from '../../../utils/Util'
import EditModal from '../../EditModal/EditModal'

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
    sx?: object
    canEdit?: boolean
    sizeOffset?: number
} & TypographyProps

const NameVerticleContent = ({
    style,
    firstName,
    lastName,
    isHighlighted,
    onClick,
    sizeOffset,
    sx
}: {
    style: IStyles
    firstName: string
    lastName: string
    isHighlighted?: boolean
    onClick?: () => void
    sizeOffset: number
    sx?: object
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const offset = firstName.length > 13 || lastName.length > 13 ? sizeOffset : 0
    const firstNameFontSize = useDynamicFontSize(firstName, containerRef, style?.NameFontSize, 0, offset)
    const lastNameFontSize = useDynamicFontSize(lastName, containerRef, style?.NameFontSize, 0, offset)

    const fontSize = Math.min(firstNameFontSize, lastNameFontSize)
    return (
        <Stack
            direction='column'
            onClick={onClick}
            ref={containerRef}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'none',
                padding: isHighlighted ? '10px' : '0',
                containerType: 'inline-size',
                ...sx
            }}>
            <Typography
                fontFamily={style[style.NameFontName]}
                fontSize={`${fontSize}px`}
                fontWeight={style.NameFontWeight ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                alignSelf={style?.NameAlign ?? 'end'}
                color={style?.NameFontColor ?? 'black'}
                letterSpacing={`${style?.NameFontLetterSpacing ?? 0}px`}
                textTransform={style?.NameFontCase ?? 'none'}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'inline-block'
                }}>
                {firstName}
            </Typography>
            <Typography
                fontFamily={style[style.NameFontName]}
                fontSize={`${fontSize}px`}
                fontWeight={style.NameFontWeight ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                color={style?.NameFontColor ?? 'black'}
                letterSpacing={`${style?.NameFontLetterSpacing ?? 0}px`}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                alignSelf={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'inline-block'
                }}>
                {lastName}
            </Typography>
        </Stack>
    )
}

const NameVerticleComponent = ({ sx = {}, sizeOffset = 0 }: FormInputProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const nameRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const names = ['personalInfo.firstName', 'personalInfo.lastName']
    const firstName = euContext.resume.personalInfo.firstName
    const lastName = euContext.resume.personalInfo.lastName

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div
                ref={nameRef}
                style={{ width: '100%' }}>
                <NameVerticleContent
                    style={style}
                    firstName={firstName}
                    lastName={lastName}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                    sizeOffset={sizeOffset}
                    sx={sx}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                width={500}
                names={names}
            />
        </>
    )
}

export default NameVerticleComponent
